import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import clickOutside from '@/directives/clickOutside'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
  faCalendarDays,
  faCameraRetro,
  faChevronLeft,
  faChevronRight,
  faCloudSunRain,
  faGamepad,
  faHouseChimney,
  faMapLocationDot,
  faMusic,
  faPalette,
  faParagraph,
  faPersonDigging,
  faXmark
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
  faCalendarDays,
  faCameraRetro,
  faChevronLeft,
  faChevronRight,
  faCloudSunRain,
  faGamepad,
  faHouseChimney,
  faMapLocationDot,
  faMusic,
  faPalette,
  faParagraph,
  faPersonDigging,
  faXmark
)

createApp(App)
.use(router)
.use(store)
.directive('click-outside', clickOutside)
.component('font-awesome-icon', FontAwesomeIcon)
.mount("#app");
