<template>
  <div id="bruh">
    <p>Bruh Moment. (This is the 404 page)</p>
    <p><RouterLink to="/">Click here</RouterLink> to go home.</p>
  </div>
</template>

<style scoped lang="scss">
#bruh {
  padding: 16px;
}
</style>
