const codes: string[] = []
codes[0] = 'So very clear'

codes[1] = 'Pretty clear'
codes[2] = 'Part cloud'
codes[3] = 'Ova-cast'

codes[45] = 'Foggin\' da hog'
codes[48] = 'Kryptonian ice breath'

codes[51] = 'Lil\' drizzle'
codes[53] = 'Drizzle'
codes[55] = 'Big drizzle'

codes[56] = 'Lil\' icy drizzle'
codes[57] = 'Dense icy drizzle'

codes[61] = 'Lil\' bitta rain'
codes[63] = 'Raining dogs and dogs, only dogs'
codes[65] = 'Heavy Rain (2010)'
codes[66] = 'Light Freezing Rain'
codes[67] = 'Freezing rain, off the chain'

codes[71] = 'Diet Coke - Pusha T'
codes[73] = 'Sorry, dropped my creatine'
codes[75] = 'Flash blizzard'

codes[77] = 'Snow grains for the gains'

codes[80] = 'Slight showers'
codes[81] = 'Rain shower power'
codes[82] = 'Violent showers'

codes[85] = 'Chistmas miracle'
codes[86] = 'Ovaflowin\' snowin\''

codes[95] = 'Ride the Lightning'
codes[96] = 'Doom II: Hail on Earth (Hey, not too rough)'
codes[99] = 'Doom II: Hail on Earth (Ultra-Violence)'

export default codes
